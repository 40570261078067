"use client";
import { useContext } from "react";
import { UserContext } from "./_components/context/user-context";
import NavMenu from "./_components/nav-menu";
import MobileHeader from "./_components/header/mobile-header";
import { Spinner } from "@material-tailwind/react";
export default function LoadingPage() {
  const { isMobile, mobileTheme } = useContext(UserContext);

  return !isMobile ? (
    <div className={"flex flex-row bg-gray-50 "}>
      <NavMenu />

      <div className={"flex flex-row w-full mt-3 ml-10 mr-10"}>
        <div className="w-full h-[98%] rounded-md outline outline-1 outline-gray-200 bg-white flex-col flex justify-center items-center m-auto animate-pulse">
          <Spinner className="w-12 h-12" />
          <h1 className=" font-bold pt-10 text-lg ">{"Loading..."}</h1>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={"flex flex-col "}
      style={{ backgroundColor: mobileTheme.BACKGROUND }}
    >
      <div className={"flex flex-col h-screen w-full"}>
        <MobileHeader />
        <div
          className="w-[95%] h-[85%] rounded-xl shadow-2xl flex flex-col justify-center items-center mt-5 m-auto  animate-pulse"
          style={{ backgroundColor: mobileTheme.BACKGROUND }}
        >
          <Spinner className="w-12 h-12" />
          <h1
            className=" font-bold text-lg pt-10"
            style={{ color: mobileTheme.FONT1 }}
          >
            {"Loading..."}
          </h1>
        </div>
      </div>
    </div>
  );
}
